const MapsModule = {
  namespaced: true,
  state: {
    // mobile: (надо бы удалить мб, а то херня какая-то)
    hasRoute: false,
    processingPinId: null,
    isDeleteRoute: false,

    // саджесты
    suggests: [],
    currentRecentAddress: null,
    suggestNotFound: false,
  },
  getters: {
    getHasRoute: state => state.hasRoute,
    getProcessingPinId: state => state.processingPinId,
    getIsDeleteRoute: state => state.isDeleteRoute,
    getSuggestsList: state => state.suggests,
    getCurrentRecentAddress: state => state.currentRecentAddress,
    getSuggestNotFound: state => state.suggestNotFound,
  },
  mutations: {
    setHasRoute(state, bool) {
      state.hasRoute = bool;
    },
    setProcessingPinId(state, date) {
      state.processingPinId = date;
    },
    setIsDeleteRoute(state, bool) {
      state.isDeleteRoute = bool;
    },
    setSuggestsList(state, res) {
      state.suggests = res;
    },
    setCurrentRecentAddress(state, recent) {
      state.currentRecentAddress = recent;
    },
    setSuggestNotFound(state, bool) {
      state.suggestNotFound = bool;
    },
  },
};

export default MapsModule;
