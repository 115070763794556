import { getCookie } from '@/utils-v2/libs/cookieWrapper.ts';

export default {
  isWebView(cb) {
    const condition = this.runtimeName === 'webview';

    if (condition && cb) {
      return cb();
    }

    return condition;
  },
  needAuthorize() {
    return !getCookie('auth::token');
    // const params = new URLSearchParams(window.location.search);
    //
    // return params.get('track_id') || !getCookie('auth::token');
  },
};
