const endpoint = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/grades`;

export function rating(client) {
  return {
    getTags() {
      return client.get(`${endpoint}/tags`);
    },
    sendRating(payload) {
      return client.post(`${endpoint}/estimate`, payload);
    },
  };
}
