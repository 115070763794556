import { getTokenByCode } from '@/api/modules/auth/getTokenByCode.js';

export default {
  runtimeName: 'webview',
  backToApp() {
    window.RahmetApp.backToRahmetApp();
  },
  handleNavigationToBack() {
    window.RahmetApp.handleNavigationToBack();
  },
  whenNavigationToBack(cb) {
    window.RahmetApp.whenNavigationToBack(cb);
  },
  cancelHandlingToBack() {
    window.RahmetApp.cancelHandlingToBack();
  },
  /**
   *
   * @param {function} cb - function that run when back handler is triggered
   * @link https://confluence.chocolife.me/pages/viewpage.action?pageId=59507967
   */
  onBackPressed(cb) {
    window.RahmetWebApp.onBackPressed = () => {
      if (!cb) {
        return false;
      }

      cb();

      return true;
    };
  },
  redirectToAuth() {
    return new Promise(resolve => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('code')) {
        resolve(urlParams.get('code'));
      } else {
        const payload = JSON.stringify({
          client_id: import.meta.env.VITE_CLIENT_ID,
          response_type: 'code',
          redirect_uri: import.meta.env.VITE_AUTH_REDIRECT_URI,
          state: '',
        });

        window.RahmetApp.authorizeV2(payload);
        window.RahmetWebApp.onAuthSuccessV2 = code => resolve(code);
        window.RahmetWebApp.onAuthDismissed = () => resolve(undefined);
        window.RahmetWebApp.onAuthDismissedV2 = () => resolve(undefined);
      }
    }).then(code => {
      if (code) {
        return getTokenByCode(code).then(() => {
          return this.runtimeName;
        });
      }

      window.RahmetApp.backToRahmetApp();
      return Promise.reject();
    });
  },
  setupWebViewApi() {
    window.RahmetWebApp = {
      onAuthSuccess() {},
      onAuthDismissed() {},
      onPaySuccess() {},
      onNativePayViewClosed() {},
      onGeoPermissionStatusDefined() {},
      didBecomeActive() {},
      onBackPressed() {},
      onKeyboardClosed() {},
    };
  },
};
