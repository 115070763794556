import { getQueryParams } from '@/utils-v2/helpers/getQueryParams.ts';

const businessBaseUrl = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/business`;
const reportsBaseUrl = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/reports`;
const addressesBaseUrl = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/common/addresses`;

export function rocket(client) {
  return {
    // company
    getCompany() {
      return client.get(`${businessBaseUrl}/company`).then(response => {
        if (response) {
          localStorage.setItem('cent_token', response.cent_token);

          return response;
        }

        return response;
      });
    },
    createCompany(phone) {
      return client.post(`${businessBaseUrl}/company`, { phone_number: `+${phone}` });
    },
    fetchAddresses() {
      return client.get(`${addressesBaseUrl}?is_customer=false`);
    },
    addAddress(payload) {
      return client.post(`${addressesBaseUrl}`, payload);
    },
    editAddress(payload) {
      const { id } = payload;
      return client.put(`${addressesBaseUrl}/${id}`, payload);
    },
    fetchTasks() {
      return client.get(`${businessBaseUrl}/company/tasks`);
    },
    fetchStatistics(payload) {
      return client.get(`${reportsBaseUrl}/statistics?${getQueryParams(payload)}`);
    },
    fetchWeatherData(payload) {
      return client.post(`/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/common/configs/get`, payload);
    },
    getTrackingBanner() {
      return client.get(`${businessBaseUrl}/banners`);
    },
  };
}
