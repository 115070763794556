export function getToday(): string {
  const today = new Date();

  return today.toISOString().slice(0, 10);
}

export function getYesterday(): string {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  return yesterday.toISOString().slice(0, 10);
}

export function getWeek(): string {
  const today = new Date();
  const weekAgo = new Date(today.setDate(today.getDate() - 7));

  return weekAgo.toISOString().slice(0, 10);
}

export function getOneMonth(): string {
  const today = new Date();
  const monthAgo = new Date(today.setMonth(today.getMonth() - 1));

  return monthAgo.toISOString().slice(0, 10);
}
