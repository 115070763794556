
<EmptyLayout v-if="$route.meta.hideLayout">
  <router-view />
</EmptyLayout>
<MainDesktop v-else-if="!isMobile">
  <router-view class="main__content" />
  <ErrorHandlerModal />
</MainDesktop>
<MainMobile v-else>
  <router-view />
  <ErrorHandlerModalMobile />
</MainMobile>
