import { authClient } from '@/api/modules/auth/client.js';
import { setTokens } from '@/utils-v2/helpers/auth/setTokens';
import { getSettingsFromAuthRequest } from '@/utils-v2/helpers/auth/generateSettingsRequest';
import { getParamsRequest } from '@/utils-v2/helpers/auth/generateParamsRequest';
import { grantTypeDictionary } from '@/entities/enums/GrantTypes';

const url = '/api/v2/oauth2/tokens';

export const getTokenByCode = code => {
  const params = getParamsRequest(grantTypeDictionary.code, { code });
  const settings = getSettingsFromAuthRequest();

  return authClient.post(url, params, settings).then(setTokens);
};
