import { api } from '@/api/modules';

const payloadFinishedOrders = {
  page: 1,
  status: '3|4',
};

const OrdersModule = {
  namespaced: true,
  state: {
    // отмены
    causes: [],
    causesLoading: false,

    // уведы
    batchingOrders: [],

    // активные доставки на телефонах
    activeOrders: [],
    currentOrderId: '',
    activeOrderId: '',
    isActiveOrdersLoading: false,
    fetchedActiveOrdersAt: '',

    lastOrderCoords: null,
  },
  getters: {
    getCauses: state => [...state.causes].reverse(),
    getCausesLoading: state => state.causesLoading,
    getBatchingOrders: state => state.batchingOrders,
    getActiveOrders: state => state.activeOrders || [],
    getActiveOrderId: state => state.activeOrderId,
    getCurrentOrderId: state => state.currentOrderId,
    isActiveOrdersLoading: state => state.isActiveOrdersLoading,
    getLastOrderCoords: state => state.lastOrderCoords,
  },
  mutations: {
    setCausesLoading(state, value) {
      state.causesLoading = value;
    },
    setCauses(state, value) {
      state.causes = value;
    },
    addBatchingOrder(state, orderId) {
      state.batchingOrders.push(orderId);
    },
    setActiveOrders(state, list) {
      state.activeOrders = list.filter(Boolean);
    },
    prependToActiveOrders(state, item) {
      state.activeOrders.unshift(item);
    },
    deleteActiveOrder(state, id) {
      state.activeOrders = state.activeOrders.filter(order => order.id !== id);
    },
    setCurrentOrderId(state, orderId) {
      state.currentOrderId = orderId;
    },
    setActiveOrderId(state, data) {
      state.activeOrderId = data;
    },
    setActiveOrdersLoading(state, bool) {
      state.isActiveOrdersLoading = bool;
    },
    setFetchedActiveOrdersAt(state) {
      state.fetchedActiveOrdersAt = Date.now();
    },
    setLastOrderCoords(state, coords) {
      state.lastOrderCoords = coords;
    },
  },
  actions: {
    loadCauses({ commit }, typeLoadCauses) {
      commit('setCausesLoading', true);

      api.orders.fetchCancelTypes(typeLoadCauses).then(data => {
        commit('setCauses', data);
        commit('setCausesLoading', false);
      });
    },
    fetchActiveOrders({ state, commit }) {
      const currentDate = Date.now();

      if (currentDate - state.fetchedActiveOrdersAt > 3000) {
        const payload = {
          page: 1,
          status: '1|2|5',
        };

        return api.orders
          .fetchOrders(payload)
          .then(res => {
            const results = res.results.filter(Boolean) || [];
            commit('setActiveOrders', results);
            commit('setFetchedActiveOrdersAt');
          })
          .catch(error => {
            console.error('error >>>', error);
          });
      }

      return Promise.resolve(true);
    },
    getLastOrderCoordsByList({ commit }, list) {
      const { address } = list[0].points[0];
      const lastCoords = [address.latitude, address.longitude];

      commit('setLastOrderCoords', lastCoords);
    },
    getLastOrderCoords({ state, dispatch }) {
      if (state.activeOrders.length > 0) {
        dispatch('getLastOrderCoordsByList', state.activeOrders);
        return;
      }

      api.orders.fetchOrders(payloadFinishedOrders).then(data => {
        if (data?.results?.length > 0) {
          dispatch('getLastOrderCoordsByList', data.results);
        }
      });
    },
  },
};

export default OrdersModule;
