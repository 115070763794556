interface NavbarItem {
  id: String;
  icon: String;
  label: String;
  route: String;
  event: String;
}

export const navbarItems: NavbarItem[] = [
  {
    id: 'home',
    icon: 'home-icon',
    label: 'mobileMenu.home',
    route: 'orders',
    event: 'navbar_click_main',
  },
  {
    id: 'orders',
    icon: 'orders-icon',
    label: 'mobileMenu.orders',
    route: 'mobile-orders',
    event: 'navbar_click_orders',
  },
  {
    id: 'menu',
    icon: 'menu-icon',
    label: 'mobileMenu.menu',
    route: 'mobile-menu',
    event: 'navbar_click_menu',
  },
];
