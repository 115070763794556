import Vue from 'vue';
import { getAddressString } from '@/utils-v2/adapters/get-address-string';
import { pay } from 'choco-app';

function getCoordinates(point) {
  return [point.address.longitude, point.address.latitude].toString().split(',').join('|');
}

function createNewPoint(id, point, changedPoint) {
  const newPoint = {
    id,
    geo_data: point.geo_data ?? changedPoint.geo_data,
    contact_info: {
      ...initialContactInfoData,
      ...changedPoint.contact_info,
      ...point.contact_info,
    },
    address: {
      ...initialAddressData,
      ...changedPoint.address,
      ...point.address,
      latitude: point.address?.latitude ? point.address.latitude : null,
      longitude: point.address?.longitude ? point.address.longitude : null,
    },
  };

  const textCoordsNotExist = !newPoint.address.latitude || !newPoint.address.longitude;
  const arrayCoordsExist = Boolean(newPoint.geo_data[0]);

  if (textCoordsNotExist && arrayCoordsExist) {
    newPoint.address.latitude =
      typeof newPoint.geo_data[0] === 'string' ? newPoint.geo_data[0] : newPoint.geo_data[0].toString();
    newPoint.address.longitude =
      typeof newPoint.geo_data[1] === 'string' ? newPoint.geo_data[1] : newPoint.geo_data[1].toString();
  }

  return newPoint;
}

const initialAddressData = {
  street: '',
  entrance: '',
  floor: '',
  building: '',
  apartment: '',
  intercom: '',
  extra_info: '',
  longitude: '',
  latitude: '',
};

const initialContactInfoData = {
  phone_number: '',
  contact_name: '',
};

const standardPointData = {
  address: { ...initialAddressData },
  contact_info: { ...initialContactInfoData },
  geo_data: [],
};

const initialPointsData = [
  {
    id: 0,
    save: false,
    ...standardPointData,
  },
  {
    id: 1,
    ...standardPointData,
  },
];

const PointsModule = {
  namespaced: true,
  state: {
    points: [...initialPointsData],
    prevCity: '',
    maxOrderDistance: null,
    cardsMinimized: [],
  },
  getters: {
    getPoints: state => state.points,
    getPhoneByPointId: state => id => state.points[id].contact_info.phone_number,
    getAddressByPointId: state => id => {
      if (!isNaN(id)) {
        return getAddressString(state.points[id].address);
      }

      return '';
    },
    getIsMultipoints: state => state.points.length > 2,
    getPointByIndex: state => index => state.points[index],
    getIsCardMinimized: state => index => state.cardsMinimized[index],
    getPointsCoordinates: state => {
      return state.points.map(point => ({ coordinates: getCoordinates(point) }));
    },
    getIsRouteCreated: state => state.points[0].address.street && state.points[1].address.street,
    getMaxOrderDistance: state => state.maxOrderDistance,
  },
  mutations: {
    setDefaultPoint(state, index) {
      Vue.set(state.points, index, {
        id: index,
        ...standardPointData,
      });
    },
    setCardMinimize(state, id) {
      Vue.set(state.cardsMinimized, id, true);
    },
    setCardMaximize(state, id) {
      Vue.set(state.cardsMinimized, id, false);
    },
    addNewEmptyPoint(state, id) {
      state.points.push({
        id,
        ...standardPointData,
      });
    },
    deletePoint(state, id) {
      state.points.splice(id, 1);

      state.points = state.points.map((point, index) => ({
        ...point,
        id: index,
      }));
    },
    setPointsInitial(state) {
      state.points = [...initialPointsData];
    },
    setPointExtraInfo(state, data) {
      state.points[data.index].address.extra_info = data.info;
    },
    setPointContactInfo(state, { index, contactInfo }) {
      const changedPoint = state.points[index];

      const newPoint = {
        ...changedPoint,
        contact_info: {
          ...initialContactInfoData,
          ...changedPoint.contact_info,
          ...contactInfo,
        },
      };

      Vue.set(state.points, index, newPoint);
    },
    setPointSaveFlag(state, bool) {
      Vue.set(state.points[0], 'save', Boolean(bool));
    },
    setPoint(state, { index, point, maxDistance }) {
      const newPoint = createNewPoint(index, point, state.points[index]);

      Vue.set(state.points, index, newPoint);

      if (index === 0 && point?.address?.city) {
        state.prevCity = point.address.city;
      }

      if (maxDistance || maxDistance === 0) {
        state.maxOrderDistance = maxDistance;
      }
    },
    setPoints(state, newPoints) {
      state.points = newPoints;
    },
  },
  actions: {
    addPointWithTasks({ commit }, id) {
      commit('addNewEmptyPoint', id);
      commit('tasks/addEmptyTask', id, { root: true });
      commit('tasks/addEmptyItemsTask', id, { root: true });
    },
    deletePoint({ commit }, id) {
      commit('deletePoint', id);
      commit('tasks/deleteTasksByPointId', id, { root: true });
      commit('tasks/deleteItemsByPointId', id, { root: true });
    },
  },
};

export default PointsModule;
