import axios from 'axios';
import { stringify } from 'qs';
import { success, failed } from '@/api/modules/auth/interceptorCallbacks.js';

/**
 * @see https://git.chocodev.kz/rocket/rocket-delivery-front/-/blob/prerelease/docs/integration/auth.md
 */
const authClient = axios.create({
  baseURL: import.meta.env.VITE_CHOCO_API,
  paramsSerializer: stringify,
  transformRequest: [data => JSON.stringify(data)],
  headers: {
    'Content-Type': 'application/json',
  },
});

authClient.interceptors.response.use(success, failed);

export { authClient };
