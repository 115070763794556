<template>
  <UiModal name="error-handler">
    <div class="error-handler">
      <div class="relative">
        <h3 class="error-handler__title">{{ modalTitle }}</h3>
        <div class="error-handler__divider"></div>
      </div>

      <div class="error-handler__content">
        <div v-if="modalText">
          <p>{{ modalText }}</p>
          <p v-if="counterError > 1">Пожалуйста, обратитесь в поддержку чтобы решить вашу проблему.</p>
        </div>
        <p v-else>
          Произошла непредвиденная серверная ошибка. Вы можете перезагрузить страницу нажав на соответствующую кнопку
          ниже. Если это не помогает, просим обратиться в службу поддержки.
        </p>
      </div>

      <div class="error-handler__controllers">
        <UiButton
          v-if="counterError > 1"
          @click="goSupport"
        >
          <span class="text-base font-bold"> Написать в поддержку </span>
        </UiButton>
        <UiButton
          v-if="refreshPageBtn"
          @click="refreshPage"
        >
          <span class="text-base font-bold"> Перезагрузить </span>
        </UiButton>
        <UiButton
          v-else
          @click="hideErrorModal"
        >
          <span class="text-base font-bold"> Понятно </span>
        </UiButton>
      </div>
    </div>
  </UiModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UiModal from '@/shared/components/ui/UiModal.vue';
import UiButton from '@ui/UiButton.vue';
import { showIntercom } from '@/utils-v2/libs/intercom';
import { errorCodesList, errorCustomCodesList } from '@/utils-v2/plugins/errorHandler/errorCodes.ts';

export default {
  name: 'ErrorHandlerModal',
  components: {
    UiModal,
    UiButton,
  },
  inject: ['modal'],
  computed: {
    ...mapGetters({
      visible: 'errorHandler/getVisible',
      errorData: 'errorHandler/getErrorData',
      typeError: 'errorHandler/getTypeError',
      counterError: 'errorHandler/getCounterError',
      getRequestInHistory: 'errorHandler/getRequestInHistory',
    }),
    isBackendError() {
      return this.typeError === 'back';
    },
    modalText() {
      if (this.isBackendError) {
        return this.textBackendError();
      }

      return this.textCustomError();
    },
    modalTitle() {
      if (this.isBackendError) {
        return this.titleBackendError();
      }

      return this.titleCustomError();
    },
    refreshPageBtn() {
      return this.errorData.status >= 500;
    },
  },
  watch: {
    visible() {
      this.toggleVisibleModal();
    },
  },
  methods: {
    ...mapActions({
      hideErrorModal: 'errorHandler/hideErrorModal',
    }),
    toggleVisibleModal() {
      if (this.visible) {
        this.modal.show('error-handler');
      } else {
        this.modal.hide('error-handler');
      }
    },
    setCounterError() {
      this.counterError = 1;
    },
    refreshPage() {
      this.hideErrorModal();
      window.location.reload();
    },
    goSupport() {
      showIntercom();
    },

    // titles
    titleBackendError() {
      return this.errorData.code && errorCodesList[this.errorData.code]
        ? errorCodesList[this.errorData.code].title
        : 'Непредвиденная ошибка';
    },
    titleCustomError() {
      const customError = errorCustomCodesList[this.errorData.code];

      if (this.errorData.code && customError) {
        const historyRequest = this.getRequestInHistory(customError.request);

        if (customError.request && historyRequest) {
          return this.titleBackendHistoryError(historyRequest);
        } else {
          return customError.title;
        }
      }

      return customError?.title ?? 'Непредвиденная ошибка';
    },
    titleBackendHistoryError(historyRequest) {
      return historyRequest.details.code && errorCodesList[historyRequest.details.code]
        ? errorCodesList[historyRequest.details.code].title
        : 'Непредвиденная ошибка';
    },

    // text
    textBackendError() {
      return this.errorData.code && errorCodesList[this.errorData.code]
        ? errorCodesList[this.errorData.code].text
        : this.errorData.message || '';
    },
    textCustomError() {
      const customError = errorCustomCodesList[this.errorData.code];

      if (this.errorData.code && customError) {
        const historyRequest = this.getRequestInHistory(customError.request);

        if (customError.request && historyRequest) {
          return this.textBackendHistoryError(historyRequest);
        } else {
          return customError.text;
        }
      }

      return customError?.text ?? '';
    },
    textBackendHistoryError(historyRequest) {
      return historyRequest.details.code && errorCodesList[historyRequest.details.code]
        ? errorCodesList[historyRequest.details.code].text
        : historyRequest.details.message || '';
    },
  },
};
</script>

<style scoped>
.error-handler {
  width: 460px;
}

.error-handler__title {
  font-size: 22px;
  margin-bottom: 8px;
  padding-bottom: 4px;
}

.error-handler__divider {
  position: absolute;
  left: -20px;
  bottom: 0;
  height: 1px;
  width: calc(100% + 40px);
  background-color: var(--desktop-text-secondary);
}

.error-handler__controllers {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;
}
</style>
