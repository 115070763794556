const reportsBaseUrl = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/reports`;

export function reports(client) {
  return {
    fetchReports(payload) {
      let URL = `${reportsBaseUrl}/api${payload.is_cod ? '-cod' : ''}?`;
      URL += payload.page ? `page=${payload.page}` : '';
      URL += payload.start_date ? `&start_date=${payload.start_date}` : '';
      URL += payload.end_date ? `&end_date=${payload.end_date}` : '';

      return client.get(URL);
    },
    fetchSingleReport(payload) {
      const URL = `${reportsBaseUrl}/api${payload.is_cod ? '-cod' : ''}/${payload.id}`;

      return client.get(URL);
    },
    sendReport(payload) {
      const URL = `${reportsBaseUrl}/generate`;

      return client.get(URL, { params: payload });
    },
  };
}
