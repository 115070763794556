import { getProviderValue } from './get-provider-value';
import providersList from './providers';

const providers = {};

/**
 * @see https://git.chocodev.kz/rocket/rocket-delivery-front/-/blob/prerelease/docs/base/di.md?ref_type=heads
 */
Object.keys(providersList).forEach(provider => {
  providers[providersList[provider].key] = getProviderValue(providersList[provider]);
});

export { providers };
