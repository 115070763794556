import { sortRates } from '@/utils-v2/helpers/store/rates';

const RatesModule = {
  namespaced: true,
  state: {
    rates: {
      tariffs: [],
    },
    selected: {},
    deliveryPrice: 0,
    optimized: {
      applied: false,
      rates: [],
      waypoints: [],
      backup: {},
    },
    optimizationProcessing: false,
  },
  getters: {
    getRates: state => state.rates.tariffs || [],
    getCurrentRate: state => state.selected,
    getDeliveryPrice: state => state.deliveryPrice,
    isCurrentRateFastest: state => state.selected.is_fastest,
    getOptimizationData: state => state.optimized,
    getOptimizationProcessStatus: state => state.optimizationProcessing,
    getProfit: state => {
      const selectedRate = state.optimized.rates.find(rate => rate.id === state.selected.id);

      if (!selectedRate) {
        return 0;
      }

      return state.selected.price - selectedRate.price;
    },
    getRateDistance: state => state.rates.distance,
  },
  mutations: {
    setRates(state, rates = {}) {
      state.rates = rates;
      state.rates.tariffs = sortRates(state.rates.tariffs || []);

      if (state.selected?.id) {
        state.selected = rates.tariffs.find(tariff => tariff.id === state.selected.id) || {};

        state.deliveryPrice = state.selected?.price || 0;
      } else {
        state.selected = rates?.tariffs[0] || {};
        state.deliveryPrice = rates?.tariffs[0]?.price || 0;
      }
    },
    setCurrentRate(state, rate) {
      state.selected = rate;
      state.deliveryPrice = rate.price;
    },
    setInitialRate(state) {
      state.rates = {};
      state.selected = {};
    },
    setOptimizationData(state, payload) {
      state.optimized.rates = !payload ? [] : payload.tariffs;
      state.optimized.waypoints = !payload ? [] : payload.waypoints;
      state.optimized.applied = false;
      state.optimized.backup = {};
    },
    setOptimizationBackup(state, payload) {
      state.optimized.backup = payload;
    },
    setOptimizationApplied(state, flag) {
      state.optimized.applied = flag;
    },
  },
};

export default RatesModule;
