import application from '@/utils-v2/di/providers/application';
import menu from '@/utils-v2/di/providers/menu';
import navbar from '@/utils-v2/di/providers/navbar';
import analytics from '@/utils-v2/di/providers/analytics';

export default {
  application,
  menu,
  navbar,
  analytics,
};
