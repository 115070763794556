const endpoint = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/business/branches`;

export function warehouse(client) {
  return {
    warehouses(page) {
      let payload = '';

      if (page) {
        payload = `?page=${page}`;
      }

      return client.get(`${endpoint}${payload}`);
    },
    deleteWarehouse(id) {
      return client.delete(`${endpoint}/${id}`);
    },
    addWarehouse(payload) {
      return client.post(`${endpoint}`, payload);
    },
    detailedWarehouse(id) {
      return client.get(`${endpoint}/${id}`);
    },
    updateWarehouse(payload) {
      const { id } = payload;
      return client.put(`${endpoint}/${id}`, payload);
    },
  };
}
