import { authClient } from '@/api/modules/auth/client.js';
import { setTokens } from '@/utils-v2/helpers/auth/setTokens';
import { getSettingsFromAuthRequest } from '@/utils-v2/helpers/auth/generateSettingsRequest';
import { getParamsRequest } from '@/utils-v2/helpers/auth/generateParamsRequest';
import { grantTypeDictionary } from '@/entities/enums/GrantTypes';

const url = '/api/v2/oauth2/tokens';

export const getTokenByRefresh = () => {
  const params = getParamsRequest(grantTypeDictionary.refresh);
  const settings = getSettingsFromAuthRequest();

  if (!params.refresh_token) {
    return null;
  }

  return authClient
    .post(url, params, settings)
    .then(data => {
      setTokens(data);

      return data.access_token;
    })
    .catch(err => {
      console.error('refresh err', err);
    });
};
