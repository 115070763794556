<template>
  <button
    :class="constructedClass"
    class="ui-button ui-button--loading text-sm text-black focus:outline-none"
    :disabled="!active"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
const SIZES = {
  sm: '--sm',
  md: '--md',
  max: '--max',
};

const TYPES = {
  primary: '--primary',
  secondary: '--secondary',
  disabled: '--disabled',
  bordered: '--bordered',
  white: '--white',
  grey: '--grey',
  red: '--red',
  loading: '--loading',
};

export default {
  name: 'UiButton',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    constructedClass() {
      return `${SIZES[this.size]} ${TYPES[this.type]} ${this.active ? '' : 'ui-button--disabled'}`;
    },
  },
};
</script>

<style>
.ui-button {
  border-radius: 6px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  @apply transition duration-75 ease-in;
}

.ui-button--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ui-button.--loading {
  background-color: var(--desktop-main-light);
  color: var(--desktop-text-secondary);
}

.ui-button.--sm {
  @apply px-2 py-1;
}

.ui-button.--md {
  @apply px-4 py-2.5;
}

.ui-button.--max {
  width: 100%;
}

.ui-button.--primary {
  @apply font-medium text-white;
  background-color: var(--desktop-main-accent);
}

.ui-button.--primary:hover {
  background-color: var(--desktop-main-accent-secondary);
}

.ui-button.--secondary {
  background-color: var(--desktop-main-tertiary);
}

.ui-button.--secondary:hover {
  background-color: #c1cedd;
}

.ui-button.--transparent:hover {
  background-color: var(--desktop-main-accent-secondary);
}

.ui-button.--disabled {
  @apply bg-gray-300 font-normal text-gray-400;
}

.ui-button.--bordered {
  @apply border border-solid border-red-400 font-normal text-red-400;
}

.ui-button.--bordered:hover {
  @apply border-red-500 text-red-500;
}

.ui-button.--white {
  @apply border border-solid border-gray-600 bg-white font-normal;
}

.ui-button.--white:hover {
  @apply bg-gray-200;
}

.ui-button.--grey {
  background: var(--desktop-main-tertiary);
  border: 1px solid var(--desktop-main-tertiary);
}

.ui-button.--red {
  @apply font-medium text-white;
  background: var(--desktop-main-error);
  border: 1px solid var(--desktop-main-error);
}
</style>
