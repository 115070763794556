import Vue from 'vue';

import VueGtag from 'vue-gtag';
import { ToastApp } from '@ui/ui-toast';
import amplitude from 'amplitude-js';

import dependencyInjectionPlugin from '@/utils-v2/di/plugin-installer.ts';
import { i18n } from '@/shared/plugins/i18n';
import { providers } from '@/utils-v2/di';
import { initSentry } from '@/utils-v2/libs/sentry.js';
import { getCurrentPlatform } from '@/utils-v2/helpers/getCurrentPlatform.ts';
import errorHandlerPlugin from '@/utils-v2/plugins/errorHandler/index.js';

import App from '@/App.vue';
import router from '@/router';
import { store } from '@store';
import { isProduction } from '@/vue-features/composables/useEnvironment.ts';
import '@/router/middlewares/index.js';
import '@/assets/styles/main.css';

// Libraries
Vue.use(dependencyInjectionPlugin);

if (isProduction()) {
  Vue.use(VueGtag, { config: { id: 'G-CH9WKMD5JJ' } });
} else {
  // rewrite this in vue 3, vue global properties >>>
  window.gtag = (name, payload) => {
    console.warn(`Triggered GA event: ${name}. Params:`, payload);
  };
  Vue.prototype.$gtag = {
    event: () => {},
  };
}

// plugins
Vue.use(errorHandlerPlugin);

// mock server
const prepare = async () => {
  if (!isProduction() && localStorage.getItem('mocksEnabled')) {
    const { worker } = await import('./mocks/index.ts');
    await worker.start();
  }
};

const platform = getCurrentPlatform();

// Sentry
initSentry(Vue);

amplitude.getInstance().init(import.meta.env.VITE_APP_AMPLITUDE_KEY, null, { platform });

providers.application.setupWebViewApi();

Vue.config.productionTip = false;

ToastApp.mount();

prepare().then(() => {
  new Vue({
    router,
    store,
    i18n,
    provide() {
      return {
        toast: ToastApp.toast,
      };
    },
    render: h => h(App),
  }).$mount('#app');
});
