
<div
  v-if="isActive"
  class="modal__backdrop"
  @click.self="hide"
>
  <div class="modal__wrapper">
    <div class="modal">
      <header class="modal__header">
        <slot name="header"></slot>
      </header>

      <section class="modal__body">
        <slot name="body"></slot>
      </section>

      <footer
        class="modal__footer"
        :class="{
          'modal__footer--row': footerRow,
        }"
      >
        <slot
          name="footer"
          :close="hide"
        ></slot>
      </footer>
    </div>
  </div>

  <div
    class="modal__blur"
    @click.self="hide"
  ></div>
</div>
