import { removeCookie } from '@/utils-v2/libs/cookieWrapper.ts';
import { authClient } from '@/api/modules/auth/client.js';
import { getSettingsFromAuthRequest } from '@/utils-v2/helpers/auth/generateSettingsRequest';

const url = 'api/v1/oauth2/devices';

export const logout = () => {
  const settings = getSettingsFromAuthRequest(true);

  return authClient.delete(url, settings).finally(() => {
    removeCookie('auth::token');
    removeCookie('auth::refresh-token');
    removeCookie('auth::expire-in');
    document.location.href = `${import.meta.env.VITE_AUTH_SERVER}/oauth/logout`;
  });
};
