import { setCookie } from '@/utils-v2/libs/cookieWrapper';

interface ITokens {
  access_token: string;
  refresh_token: string;
  expires_in: number | string;
}

export function setTokens(tokens: ITokens) {
  setCookie('auth::token', tokens.access_token);
  setCookie('auth::refresh-token', tokens.refresh_token);
  setCookie('auth::expire-in', tokens.expires_in.toString());
}
