import webviewNavbarItems from '@/utils-v2/di/providers/navbar/webview-navbar.providers';
import webNavbarItems from '@/utils-v2/di/providers/navbar/web-navbar.providers';

const providerKey: String = 'navbar';

export default {
  key: providerKey,
  value() {
    return window.RahmetApp ? webviewNavbarItems : webNavbarItems;
  },
};
