import CommonApplication from '@/utils-v2/di/providers/application/common-application.provider';
import WebApplication from '@/utils-v2/di/providers/application/web-application.provider';
import WebViewApplication from '@/utils-v2/di/providers/application/webview-application.provider';

const providerKey = 'application';

export default {
  key: providerKey,
  value() {
    return window.RahmetApp ? WebViewApplication : WebApplication;
  },
  common: CommonApplication,
};
