const RocketModule = {
  namespaced: true,
  state: {
    userLocation: {
      longitude: null,
      latitude: null,
    },
    userLocationError: false,
  },
  getters: {
    getUserLocation: state => state.userLocation,
    getUserLocationError: state => state.userLocationError,
  },
  mutations: {
    setUserLocation(state, userLocation) {
      state.userLocation = {
        longitude: userLocation.longitude,
        latitude: userLocation.latitude,
      };
    },
    setUserLocationError(state, error) {
      state.userLocationError = error;
    },
  },
};

export default RocketModule;
