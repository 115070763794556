<template>
  <button
    class="mui-button"
    :class="{
      'mui-button--disabled': disabled,
      'mui-button--secondary': secondary,
      'mui-button--loading': showLoader,
    }"
    @click.stop="$emit('click')"
  >
    <UiLoader v-if="showLoader" />
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: 'MUiButton',
  components: {
    UiLoader: () => import('@ui/UiLoader.vue'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.mui-button {
  width: 100%;
  padding: 16px;
  color: var(--mobile-main-white);
  background: var(--mobile-main-accent);
  border-radius: 12px;
  font-weight: 900;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.mui-button--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.mui-button--loading {
  max-height: 52px;
  padding: 12px;
}

.mui-button--secondary {
  background-color: var(--mobile-main-grey-light);
  color: var(--mobile-main-black);
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 110%;
}
.ui-loader {
  width: 28px;
  height: 28px;
}
</style>
