import axios from 'axios';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { getCookie } from '@/utils-v2/libs/cookieWrapper.ts';
import { getTokenByRefresh } from '@/api/modules/auth/getTokenByRefresh.js';
import { pushApiError, sentryDebug } from '@/utils-v2/libs/sentry';
import { transformError } from '@/api/transform-error';
import { providers } from '@/utils-v2/di';
import { errorHandlerWrapper, errorHandlerHistoryClear } from '@/utils-v2/plugins/errorHandler/helpers.js';
import router from '@/router';

const client = axios.create({
  baseURL: import.meta.env.VITE_CHOCO_API,
  paramsSerializer: stringify,
  headers: {
    'Accept-Language': localStorage.getItem('lang') || 'ru',
  },
});

const telegramClient = axios.create({
  baseURL: import.meta.env.VITE_APP_BOT_URL,
});

function resolveError(error) {
  sentryDebug({ title: 'API ERROR', message: transformError(error) });
  return Promise.reject(transformError(error));
}

function successResponseHandler(response) {
  errorHandlerHistoryClear(response.request.responseURL);

  return response.data;
}

async function failedResponseHandler(error) {
  const errorCode = error?.response?.status;

  if (errorCode === 401) {
    /**
     * @see https://git.chocodev.kz/rocket/rocket-delivery-front/-/blob/prerelease/docs/integration/auth.md
     */
    const { config } = error.response;

    try {
      if (!router.currentRoute.meta?.unprotected) {
        const newAccessToken = await getTokenByRefresh();

        if (!newAccessToken) {
          localStorage.setItem('redirectUrl', window.location.href);

          return providers.application.redirectToAuth();
        }

        config.headers.Authorization = `Bearer ${newAccessToken}`;
      }

      config.headers['Content-Type'] = 'application/json';

      /* eslint no-underscore-dangle: ["error", { "allow": ["_retry"] }] */
      if (!config._retry) {
        config._retry = true;

        return client(config);
      }
    } catch (err) {
      console.error('interceptor error', err);
      await resolveError(err);
    }
  } else {
    errorHandlerWrapper(error);

    if (errorCode !== 400) {
      pushApiError(error.response);
    }
  }

  throw error;
}

client.interceptors.request.use(
  config => {
    config.headers['X-Idempotency-key'] = uuidv4();
    config.headers['Content-Type'] = 'application/json';

    if (getCookie('auth::token')) {
      config.headers.Authorization = `Bearer ${getCookie('auth::token')}`;
    }

    return config;
  },
  err => {
    resolveError(err);
    return Promise.reject(err);
  },
);

client.interceptors.response.use(successResponseHandler, failedResponseHandler);

export { client, telegramClient };
