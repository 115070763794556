import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let mainPage = () => import('@views/orders/view.vue');

if (window.innerWidth <= 650) {
  mainPage = () => import('@views/mobile/view.vue');
}

const routes = [
  {
    path: '/',
    name: 'orders',
    component: mainPage,
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('@views/delivery/view.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@views/reports/view.vue'),
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import('@views/integration/view.vue'),
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: () => import('@views/warehouses/view.vue'),
  },
  {
    path: '/warehouses/new',
    name: 'warehouses-new',
    component: () => import('@views/warehouses-new/view.vue'),
  },
  {
    path: '/warehouse/edit/:id',
    name: 'warehouse-edit',
    component: () => import('@views/warehouse-edit/view.vue'),
  },
  {
    path: '/orders/new',
    name: 'orders-new',
    component: () => import('@views/orders-new/view.vue'),
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@views/payments/view.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@views/statistics/view.vue'),
  },
  {
    path: '/employers',
    name: 'employers',
    component: () => import('@views/employers/view.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    redirect() {
      return { path: '/' };
    },
  },
  {
    path: '/mobile/new-order-details',
    name: 'new-order-details',
    component: () => import('@views/mobile/pages/new-order-details/view.vue'),
  },
  {
    path: '/mobile/menu',
    name: 'mobile-menu',
    component: () => import('@views/mobile/pages/menu/Menu.vue'),
  },
  {
    path: '/mobile/orders',
    name: 'mobile-orders',
    component: () => import('@views/mobile/pages/orders/Orders.vue'),
  },
  {
    path: '/mobile/orders/:id',
    name: 'order-details',
    component: () => import('@views/mobile/pages/order-details/view.vue'),
  },
  {
    path: '/mobile/change-lang',
    name: 'change-lang',
    component: () => import('@views/mobile/pages/change-lang/view.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/auth/login/view.vue'),
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/terms/ru',
    name: 'delivery-terms-ru',
    component: () => import('@/views/delivery-terms/ru/view.vue'),
    meta: {
      unprotected: true,
      hideLayout: true,
    },
  },
  {
    path: '/terms/kk',
    name: 'delivery-terms-kk',
    component: () => import('@/views/delivery-terms/kk/view.vue'),
    meta: {
      unprotected: true,
      hideLayout: true,
    },
  },
  {
    path: '/privacy-policy/ru',
    name: 'delivery-privacy-policy-ru',
    component: () => import('@/views/delivery-policy/ru/view.vue'),
    meta: {
      unprotected: true,
      hideLayout: true,
    },
  },
  {
    path: '/tracking',
    name: 'client-tracking',
    component: () => import('@/views/client-tracking/view.vue'),
    meta: {
      hideLayout: true,
      unprotected: true,
    },
  },
  {
    path: '*',
    component: () => import('@views/404/view.vue'),
    meta: {
      unprotected: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
