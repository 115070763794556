<template>
  <div
    v-if="isActive"
    class="modal__backdrop"
    @click.self="hide"
  >
    <div class="modal__wrapper">
      <div class="modal">
        <header class="modal__header">
          <slot name="header"></slot>
        </header>

        <section class="modal__body">
          <slot name="body"></slot>
        </section>

        <footer
          class="modal__footer"
          :class="{
            'modal__footer--row': footerRow,
          }"
        >
          <slot
            name="footer"
            :close="hide"
          ></slot>
        </footer>
      </div>
    </div>

    <div
      class="modal__blur"
      @click.self="hide"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ChocoModal',
  props: {
    footerRow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isActive: false,
  }),
  methods: {
    hide() {
      this.isActive = false;
    },
    show() {
      this.isActive = true;
    },
  },
};
</script>

<style scoped>
.modal {
  background: var(--mobile-main-white);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  padding: 24px 16px 16px;
  border-radius: 24px;
}

.modal__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal__blur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: -1;
}

.modal__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__header,
.modal__footer {
  display: flex;
}

.modal__header {
  position: relative;
  justify-content: center;
  font-weight: 900;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  color: var(--mobile-main-black);
}

.modal__footer {
  flex-direction: column;
}

.modal__footer--row {
  flex-direction: row;
  gap: 8px;
}

.modal__body {
  padding: 8px 0 24px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
