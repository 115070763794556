const proxyHandler = {
  get(target, prop) {
    if (prop in target) {
      if (typeof target[prop] === 'function') {
        return new Proxy(target[prop], {
          apply: (targetFn, thisArg, argumentsList) => {
            return Reflect.apply(targetFn, thisArg, argumentsList);
          },
        });
      }

      return Reflect.get(target, prop);
    }

    return () => {};
  },
};

const pullValue = (value: object) => {
  if (typeof value === 'function') {
    return value();
  }

  return value;
};

type ProviderValue = {
  key: string;
  value: any;
};

export const getProviderValue = ({ value, common = {} }): ProviderValue => {
  const mergedValue = { ...common, ...pullValue(value) };

  return new Proxy(mergedValue, proxyHandler);
};
