export function notify(client) {
  return {
    sendMessage(message) {
      client.post('/sendMessage', {
        chat_id: import.meta.env.VITE_APP_CHAT_ID,
        text: message,
      });
    },
  };
}
