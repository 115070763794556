import { store } from '@/store';
import { api } from '@/api/modules';
import { ignoreList, ignoreTelegramList, storeGettersInNotify, savedHistorySettings } from './settings';

export function errorInIgnoresList(error) {
  const currentUrl = error.response?.config?.url || '';
  const status = error?.response?.status || null;
  const code = error.response?.data?.detail?.code || error.response?.data?.code || '';

  return Object.entries(ignoreList).some(
    ([url, itemsIgnore]) =>
      currentUrl.includes(url) && (itemsIgnore.statuses.includes(status) || itemsIgnore.codes.includes(code)),
  );
}

export function errorInTelegramIgnoreList(error) {
  const currentCode = error.response.data.detail?.code || error.response.data.code;

  if (!currentCode) return false;

  return ignoreTelegramList.includes(currentCode);
}

function setErrorDataInStore(error) {
  const errorHandlerData = {
    status: error.response.status,
    code: error.response.data.detail?.code || error.response.data.code,
    url: error.response.config.url,
    message: error.response.data.detail?.message || error.response.data.message,
    type: 'back',
  };

  store.commit('errorHandler/setErrorData', errorHandlerData);
  store.dispatch('errorHandler/showErrorModal');
}

function getStoreData(key) {
  const storeGetters = storeGettersInNotify.find(item => item.code === key || key.includes(item.request))?.getters;

  if (!storeGetters) return null;

  let resultData = '';

  storeGetters.forEach(getter => {
    resultData += `${getter}: {${JSON.stringify(store.getters[getter])}}`;
  });

  return resultData;
}

function sendTelegramNotify(error) {
  const storeData = getStoreData(error.response.config.url);

  const payload = [
    `url: ${error.response.config.url}`,
    `method: ${error.response.config.method}`,
    `code: ${error.response.data.detail?.code || error.response.data.code}`,
    `status: ${error.response.status}`,
    `userId: ${store.state.user.id}`,
    `companyName: ${store.state.user.companyData.name}`,
    `frontPayload: ${error.response.config.data}`,
  ];

  if (storeData) {
    payload.push(`store: {${storeData}}`);
  }

  api.notify.sendMessage(payload.join(`\n\n`));
}

function sendTelegramCustomErrorNotify(code) {
  const storeData = getStoreData(code);

  const payload = [
    `userId: ${store.state.user.id}`,
    `companyName: ${store.state.user.companyData.name}`,
    `code: ${code}`,
  ];

  if (storeData) {
    payload.push(`store: {${storeData}}`);
  }

  api.notify.sendMessage(payload.join(`\n\n`));
}

function isUrlInSavedHistoryExist(url) {
  return Object.keys(savedHistorySettings).some(key => url.includes(key));
}

function setHistoryInStore(error) {
  const url = error.response?.config?.url;
  if (!url) return;

  const isNeededSavedHistory = isUrlInSavedHistoryExist(url);

  if (isNeededSavedHistory) {
    store.dispatch('errorHandler/addHistoryItem', {
      request: url,
      details: {
        code: error.response.data.detail?.code || error.response.data.code,
        message: error.response.data.detail?.message || error.response.data.message,
      },
    });
  }
}

function clearCacheHistory(request) {
  const errorHandlerCache = JSON.parse(sessionStorage.getItem('error-handler-data'));

  if (errorHandlerCache?.url && request.includes(errorHandlerCache.url)) {
    sessionStorage.removeItem('error-handler-data');
  }
}

function errorHandlerHistoryClear(request) {
  store.dispatch('errorHandler/deleteHistoryItem', request);
  clearCacheHistory(request);
}

function errorHandlerWrapper(error) {
  setHistoryInStore(error);

  if (!errorInIgnoresList(error)) {
    setErrorDataInStore(error);

    if (!errorInTelegramIgnoreList(error)) {
      sendTelegramNotify(error);
    }
  }
}

function pushCustomErrorInErrorHandler(data) {
  const errorHandlerData = {
    code: data.code,
    message: data.message ?? null,
    type: 'custom',
    status: null,
    url: '',
  };

  store.commit('errorHandler/setErrorData', errorHandlerData);
  store.dispatch('errorHandler/showErrorModal');
  sendTelegramCustomErrorNotify(data.code);
}

export { errorHandlerWrapper, pushCustomErrorInErrorHandler, sendTelegramCustomErrorNotify, errorHandlerHistoryClear };
