import Cookies from 'js-cookie';
import { CookieAttributes } from 'js-cookie';

function setCookie(key: string, value: string, parameters?: CookieAttributes) {
  Cookies.set(key, value, parameters);
}

const getCookie = (key: string) => Cookies.get(key);
const removeCookie = (key: string) => Cookies.remove(key);

export { setCookie, getCookie, removeCookie };
