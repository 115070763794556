import { IRate } from '@/entities/types/interfaces/backend/rates';

const getRateWeight = (rate: IRate): number => {
  let weight = 0;

  if (rate.is_fastest) {
    weight += 4;
  }

  if (rate.transport === 'CAR') {
    weight += 1;
  }

  return weight;
};

export function sortRates(tariffs: IRate[]): IRate[] {
  return tariffs.sort((curr, prev) => getRateWeight(curr) - getRateWeight(prev));
}
