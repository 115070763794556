import Vue from 'vue';

function comparisonErrorHandlerData(state, { code, status, url }) {
  if (status && url) {
    return code === state.errorTextCode && status === state.errorStatusCode && url === state.errorUrl;
  }

  return code === state.errorTextCode;
}

function setCounterErrorInSessionStorage(state, count) {
  sessionStorage.setItem(
    'error-handler-data',
    JSON.stringify({
      code: state.errorTextCode,
      status: state.errorStatusCode,
      url: state.errorUrl,
      count,
    }),
  );
}

const ErrorHandlerModule = {
  namespaced: true,
  state: {
    visible: false,
    errorTextCode: '',
    errorStatusCode: null,
    errorUrl: '',
    errorMessage: '',
    type: null, // 'back' or 'custom',
    counterError: 0,
    history: [],
  },
  getters: {
    getVisible: state => state.visible,
    getErrorData: state => {
      return {
        code: state.errorTextCode,
        status: state.errorStatusCode,
        url: state.errorUrl,
        message: state.errorMessage,
      };
    },
    getTypeError: state => state.type,
    getCounterError: state => state.counterError,
    getRequestInHistory: state => request => {
      return state.history.find(hist => hist.request.includes(request) || request.includes(hist.request)) || null;
    },
  },
  mutations: {
    setVisible(state, visible) {
      state.visible = visible;
    },
    setErrorData(state, data) {
      state.errorUrl = data.url;
      state.errorTextCode = data.code;
      state.errorStatusCode = data.status;
      state.errorMessage = data.message;
      state.type = data.type ?? 'back';
    },
    setTypeError(state, type) {
      state.type = type;
    },
    setCounterError(state, count) {
      state.counterError = count;
    },
    setHistory(state, history) {
      Vue.set(state, 'history', history);
    },
  },
  actions: {
    hideErrorModal({ commit }) {
      commit('setVisible', false);
    },
    showErrorModal({ commit, dispatch }) {
      commit('setVisible', true);
      dispatch('countingError');
    },
    countingError({ commit, state }) {
      const data = JSON.parse(sessionStorage.getItem('error-handler-data'));
      const newCount = data && comparisonErrorHandlerData(state, data) ? +data.count + 1 : 1;

      setCounterErrorInSessionStorage(state, newCount);
      commit('setCounterError', newCount);
    },
    addHistoryItem({ commit, state }, item) {
      let newHistory = JSON.parse(JSON.stringify(state.history));
      const histExistIndex = newHistory.findIndex(hist => hist.request === item.request);

      if (histExistIndex > -1) {
        newHistory[histExistIndex] = { ...item };
      } else {
        newHistory.push(item);
      }

      commit('setHistory', newHistory);
    },
    deleteHistoryItem({ commit, state }, request) {
      let newHistory = JSON.parse(JSON.stringify(state.history));
      const histExistIndex = newHistory.findIndex(hist => hist.request === request);

      if (histExistIndex > -1) {
        newHistory.splice(histExistIndex, 1);
        commit('setHistory', newHistory);
      }
    },
    clearHistory({ commit }) {
      commit('setHistory', []);
    },
  },
};

export default ErrorHandlerModule;
