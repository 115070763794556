const endpoint = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0`;

export function user(client) {
  return {
    profile() {
      return client.get('/api/v3/user');
    },
    fetchClientToken() {
      return client.post(`${endpoint}/common/get-jwt-token`);
    },
    fetchPaymentCards() {
      return client.get(`${endpoint}/payments/ioka/get-cards`);
    },
    saveCity(payload) {
      return client.patch(`${endpoint}/business/update-geo-city/`, payload);
    },
  };
}
