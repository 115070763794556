import { getToday, getOneMonth } from '@/utils-v2/helpers/date/getElementDate.ts';

const ReportsModule = {
  namespaced: true,
  state: {
    filters: {
      start: getOneMonth(),
      end: getToday(),
    },
  },
  getters: {
    getReportFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    SET_REPORTS_FILTER(state, dates) {
      state.filters.start = dates.start;
      state.filters.end = dates.end;
    },
  },
};

export default ReportsModule;
