interface IAddress {
  street: string;
  building: string | number;
}

export function getAddressString(address: IAddress): string {
  const result = [address.street, address.building];

  return result.filter(Boolean).join(', ');
}
