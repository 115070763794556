import logoutIcon from '@/assets/images/mobile-v2/menu/logout.svg';
import langIcon from '@/assets/images/mobile-v2/menu/change-lang.svg';
import { commonItems } from '@/utils-v2/di/providers/menu/common-items';
import { logout } from '@/api/modules/auth/logout.js';

export default {
  items: [
    ...commonItems,
    {
      id: 'change_lang',
      name: 'shared.navigation.viewLang',
      icon: langIcon,
      route: 'change-lang',
      event: 'language',
      order: 4,
    },
    {
      id: 'logout_action',
      name: 'shared.navigation.logout',
      icon: logoutIcon,
      onClick: logout,
      route: '',
      event: 'logout',
      order: 999,
    },
  ],
};
