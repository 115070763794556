import WebMenu from '@/utils-v2/di/providers/menu/web-menu.provider';
import WebViewMenu from '@/utils-v2/di/providers/menu/webview-menu.provider';

const providerKey = 'menu';

export default {
  key: providerKey,
  value() {
    return window.RahmetApp ? WebViewMenu : WebMenu;
  },
};
