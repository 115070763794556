import { api } from '@/api/modules';
import { providers } from '@/utils-v2/di';

const UserModule = {
  namespaced: true,
  state: {
    id: null,
    firstName: null,
    lastName: null,
    phone: null,
    avatar: null,
    addresses: null,
    balance: null,
    email: null,
    isChocoFoodSaas: false,
    companyData: {},
  },
  getters: {
    getUserProfile: state => state,
    getUserSavedAddresses: state => state.addresses || [], // @todo: здесь должны быть все сохранённые адреса
    getUserBalance: state => state.balance,
    getHideReports: state => state.isChocoFoodSaas,
    getCompanyData: state => state.companyData,
    getIsKaspiBox: state => state.companyData.is_kaspi_box,
  },
  mutations: {
    setUserProfile(state, value) {
      const { id, firstName, lastName, phone, avatar, email } = value;

      state.id = id;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = phone;
      state.avatar = avatar;
      state.email = email;
    },
    setUserAddresses(state, value) {
      state.addresses = value;
    },
    setBalance(state, value) {
      state.balance = value;
    },
    setUserChocofoodSaas(state, value) {
      state.isChocoFoodSaas = value;
    },
    setCompanyData(state, company) {
      state.companyData = {
        ...company,
      };
    },
  },
  actions: {
    fetchBalance(ctx) {
      return api.rocket
        .getCompany()
        .then(res => {
          ctx.dispatch('setBalanceData', res);
          ctx.dispatch('setCompanyData', res);
        })
        .catch(error => {
          if (error.response.status === 404) {
            ctx.dispatch('createCompany');
          }
        });
    },
    setCompanyData(ctx, res) {
      ctx.commit('setCompanyData', res);
    },
    async createCompany(ctx) {
      const res = await api.rocket.createCompany(ctx.state.phone);

      ctx.dispatch('setBalanceData', res);
    },
    setBalanceData(ctx, res) {
      ctx.commit('setBalance', res.balance);
      ctx.commit('setUserChocofoodSaas', res.use_chocofood_SaaS);
    },
    fetchUserAddresses(ctx) {
      api.rocket.fetchAddresses().then(res => {
        if (res?.results?.length) {
          ctx.commit('setUserAddresses', res.results);
        }
      });
    },
    fetchUserProfile(ctx) {
      return api.user
        .profile()
        .then(res => {
          // @todo: удалить легаси ифку от гейтвея
          if (res.error_code !== 401) {
            const { id, attributes } = res.data;
            const { first_name: firstName, last_name: lastName, email, phone, avatar } = attributes;

            ctx.commit('setUserProfile', {
              id,
              firstName,
              lastName,
              phone,
              avatar,
              email,
            });

            ctx.dispatch('fetchBalance');
          } else {
            providers.application.redirectToAuth();
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};

export default UserModule;
