import addressIcon from '@/assets/images/mobile-v2/menu/address.svg';
import supportIcon from '@/assets/images/mobile-v2/menu/support.svg';
import faqIcon from '@/assets/images/mobile-v2/menu/faq.svg';
// import deliveryIcon from '@/assets/images/mobile-v2/menu/delivery-conditions.svg';
import offerIcon from '@/assets/images/mobile-v2/menu/offer.svg';

import { showIntercom } from '@/utils-v2/libs/intercom';

interface MenuItems {
  id: String;
  name: String;
  icon: String;
  route?: String;
  order: Number;
  state?: String;
  event?: String;
  onClick?: Function;
}

export const commonItems: MenuItems[] = [
  // {
  //   id: 'saved_addresses',
  //   name: 'shared.navigation.savedAddresses',
  //   icon: addressIcon,
  //   state: 'MenuUserAddresses',
  //   event: 'saved_addresses',
  //   route: '',
  //   order: 2,
  // },
  {
    id: 'need_support',
    name: 'shared.navigation.support',
    icon: supportIcon,
    event: 'help',
    order: 3,
    onClick: () => {
      showIntercom();
    },
  },
  {
    id: 'faq_page',
    name: 'shared.navigation.faq',
    icon: faqIcon,
    route: `https://chocodostavka.kz/${localStorage.getItem('lang') === 'kk' ? 'kaz' : ''}#faq`,
    event: 'faq',
    order: 5,
  },
  // {
  //   id: 'terms_page',
  //   name: 'shared.navigation.deliveryTerms',
  //   icon: deliveryIcon,
  //   state: 'MenuTerms',
  //   event: 'delivery_terms',
  //   order: 6,
  // },
  {
    id: 'offer_page',
    name: 'shared.navigation.publicOffer',
    icon: offerIcon,
    route: 'https://chocodostavka.kz/agreement',
    event: 'terms_and_conditions',
    order: 7,
  },
];
