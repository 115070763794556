import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import ErrorHandlerModule from '@store/modules/error-handler';
import RocketModule from '@store/modules/rocket';
import RatesModule from '@store/modules/rates';
import TasksModule from '@store/modules/tasks';
import OrdersModule from '@store/modules/orders';
import UserModule from '@store/modules/user';
import DeviceModule from '@store/modules/device';
import MapsModule from '@store/modules/maps';
import PointsModule from '@store/modules/points';

import ReportsModule from '@store/modules/reports';
import MobileModule from '@store/modules/mobile-v2/index';
import MobileDetailsModule from '@store/modules/mobile-v2/details';

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: true,
  modules: {
    user: UserModule,
    device: DeviceModule,
    rates: RatesModule,
    reports: ReportsModule,
    points: PointsModule,
    orders: OrdersModule,
    tasks: TasksModule,
    maps: MapsModule,

    mobile: MobileModule,
    mobile_details: MobileDetailsModule,

    errorHandler: ErrorHandlerModule,
    rocket: RocketModule,
  },
  plugins: [
    createPersistedState({
      key: `rocket-store-v5`,
      paths: ['points', 'rates', 'tasks', 'mobile', 'mobile_details'],
    }),
  ],
});
