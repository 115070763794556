import { user } from '@/api/modules/user';
import { rocket } from '@/api/modules/rocket';
import { orders } from '@/api/modules/orders';
import { reports } from '@/api/modules/reports';
import { warehouse } from '@/api/modules/warehouse';
import { notify } from '@/api/modules/notify';
import { rating } from '@/api/modules/rating';
import { client, telegramClient } from '../index';

export const api = {
  user: user(client),
  rocket: rocket(client),
  orders: orders(client),
  reports: reports(client),
  warehouse: warehouse(client),
  notify: notify(telegramClient),
  rating: rating(client),
};
