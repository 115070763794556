import { grantTypeDictionary, TGrantType } from '@/entities/enums/GrantTypes';
import { getCookie } from '@/utils-v2/libs/cookieWrapper';

// @todo: типизировать, как перегрузку функции
interface IData {
  [key: string]: string;
}

interface IParams {
  client_id: string;
  grant_type: string;

  // @todo: типизировать нормально йомайо
  track_id?: string;
  code?: string;
  refresh_token?: string | undefined;
  redirect_uri?: string;
}

function getParamsRequest(type: TGrantType, data: IData): IParams {
  // eslint-disable-next-line prefer-const
  let params: IParams = {
    client_id: import.meta.env.VITE_CLIENT_ID,
    grant_type: type,
  };

  if (type === grantTypeDictionary.trackId) {
    params.track_id = data.trackId;
  } else if (type === grantTypeDictionary.code) {
    params.redirect_uri = import.meta.env.VITE_AUTH_REDIRECT_URI;
    params.code = data.code;
  } else if (type === grantTypeDictionary.refresh) {
    params.refresh_token = getCookie('auth::refresh-token');
  }

  return params;
}

export { getParamsRequest };
