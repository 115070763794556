
<button
  class="mui-button"
  :class="{
    'mui-button--disabled': disabled,
    'mui-button--secondary': secondary,
    'mui-button--loading': showLoader,
  }"
  @click.stop="$emit('click')"
>
  <UiLoader v-if="showLoader" />
  <slot v-else></slot>
</button>
