import { getCookie } from '@/utils-v2/libs/cookieWrapper';
import { getHeadersXKeys } from '@/utils-v2/helpers/auth/getHeadersXKeys';
import { IXKeys } from '@/entities/types/interfaces/backend/auth';

interface IHeaders extends IXKeys {
  Authorization?: string;
}

interface ISettings {
  headers: IHeaders;
}

function getSettingsFromAuthRequest(auth: Boolean = false) {
  // eslint-disable-next-line prefer-const
  let settings: ISettings = {
    headers: getHeadersXKeys(),
  };

  if (auth) {
    settings.headers.Authorization = `Bearer ${getCookie('auth::token')}`;
  }

  return settings;
}

export { getSettingsFromAuthRequest };
