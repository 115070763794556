<template>
  <EmptyLayout v-if="$route.meta.hideLayout">
    <router-view />
  </EmptyLayout>
  <MainDesktop v-else-if="!isMobile">
    <router-view class="main__content" />
    <ErrorHandlerModal />
  </MainDesktop>
  <MainMobile v-else>
    <router-view />
    <ErrorHandlerModalMobile />
  </MainMobile>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { initIntercom, setIntercomSettings, showIntercomEvent, hideDialogIntercom } from '@/utils-v2/libs/intercom.ts';
import { useGetUserLocation } from '@/vue-features/composables/useGetUserLocation';
import ErrorHandlerModal from '@/components/business/ErrorHandlerModal.vue';
import ErrorHandlerModalMobile from '@/components/business/ErrorHandlerModalMobile.vue';

export default {
  name: 'App',
  components: {
    MainDesktop: () => import('@/layouts/MainDesktop.vue'),
    MainMobile: () => import('@/layouts/MainMobile.vue'),
    EmptyLayout: () => import('@/layouts/EmptyLayout.vue'),
    ErrorHandlerModal,
    ErrorHandlerModalMobile,
  },
  computed: mapGetters({
    isMobile: 'device/isMobile',
    profile: 'user/getUserProfile',
  }),
  created() {
    const queryParams = new URLSearchParams(window.location.href);
    const forceQueryLanguage = queryParams.get('force-lang');

    if (forceQueryLanguage) {
      localStorage.setItem('lang', forceQueryLanguage);
    }

    this.setScreenWidth(window.innerWidth);
    if (window.location.origin === import.meta.env.VITE_OLD_URL) {
      window.location.replace(import.meta.env.VITE_NEW_URL);
    }
  },
  mounted() {
    this.loadUserLocation();
    this.setDynamicAppHeight();
    this.loadIntercom();
  },
  methods: {
    ...mapMutations({
      setScreenWidth: 'device/setScreenWidth',
      setUserLocation: 'rocket/setUserLocation',
    }),
    loadUserLocation() {
      const { getUserLocation } = useGetUserLocation(position => {
        this.setUserLocation({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
      });

      getUserLocation();
    },
    setDynamicAppHeight() {
      const dynamicAppHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--dynamic-app-height', `${window.innerHeight}px`);
      };

      window.addEventListener('resize', dynamicAppHeight);
      dynamicAppHeight();
    },
    loadIntercom() {
      setTimeout(() => {
        if (!this.$route.meta.hideLayout) {
          setIntercomSettings(
            this.profile.companyData.id || this.profile.id,
            this.profile.companyData.name || this.profile.companyData.contact_name || this.profile.firstName,
            this.profile.email || this.profile.phone,
            this.isMobile,
          );

          initIntercom();
          showIntercomEvent(this.clickOutsideIntercom);
        }
      }, 3000);
    },
    clickOutsideIntercom() {
      setTimeout(() => {
        document.addEventListener('click', this.clickOutsideFunc);
      }, 1000);
    },
    clickOutsideFunc(event) {
      const container = document.querySelector('.intercom-messenger-frame');

      if (container) {
        const isClickInside = container.contains(event.target);

        if (!isClickInside) {
          hideDialogIntercom();

          document.removeEventListener('click', this.clickOutsideFunc);
        }
      }
    },
  },
};
</script>

<style scoped>
.main__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 110px;
}

@media screen and (max-width: 1024px) {
  .main__content {
    padding-left: 0;
  }
}
</style>
