import { getQueryParams } from '@/utils-v2/helpers/getQueryParams';

export default {
  runtimeName: 'web',
  redirectToAuth() {
    const query = {
      redirect_uri: import.meta.env.VITE_AUTH_REDIRECT_URI,
      client_id: import.meta.env.VITE_CLIENT_ID,
    };

    const url = `${import.meta.env.VITE_AUTH_SERVER}/oauth/login?${getQueryParams(query)}`;

    window.open(url, '_self');
  },
};
