
<transition
  name="slide-up"
  appear
>
  <div
    class="pointer-events-auto max-w-full rounded-md px-6 py-3 text-center text-sm leading-tight text-white duration-300"
    style="background: rgba(0, 0, 0, 0.6)"
    v-on="$listeners"
  >
    <slot></slot>
  </div>
</transition>
