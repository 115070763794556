const DeviceModule = {
  namespaced: true,
  state: {
    width: null,
    isMobile: false,
    isTablet: false,
  },
  getters: {
    isMobile: state => state.isMobile,
    isTablet: state => state.isTablet,
  },
  mutations: {
    setScreenWidth(state, value) {
      state.width = value;
      state.isMobile = state.width <= 650;
      state.isTablet = state.width <= 1024;

      // @todo: костыль для локалей
      if (state.width > 1024) {
        localStorage.setItem('lang', 'ru');
      }
    },
  },
};

export default DeviceModule;
