import { store } from '@store';
import { providers } from '@/utils-v2/di';
import { setSentryUserId } from '@/utils-v2/libs/sentry.js';
import amplitude from 'amplitude-js';
import router from '..';

router.beforeEach(async (to, from, next) => {
  const lazyLoadActiveOrders = () => {
    if (store.state.device.isMobile) {
      store.dispatch('orders/fetchActiveOrders');
    }

    next();
  };

  const initUserData = async () => {
    await store
      .dispatch('user/fetchUserProfile')
      .then(async () => {
        const { id } = store.state.user;
        setSentryUserId(id);
        amplitude.getInstance().setUserId(id);
      })
      .catch(providers.application.redirectToAuth);

    lazyLoadActiveOrders();
  };

  providers.application.onBackPressed(false);

  if ('unprotected' in to.meta) {
    next();
  } else if (!store.state.user.id) {
    await initUserData();
  } else {
    lazyLoadActiveOrders();
  }
});
