import { store } from '@/store';

const options = {
  maximumAge: 25000,
  timeout: 25000,
};

/**
 * @see https://git.chocodev.kz/rocket/rocket-delivery-front/-/blob/prerelease/docs/base/geolocation.md?ref_type=heads
 */
export const useGetUserLocation = successCallback => {
  function fetchIpData() {
    const url = import.meta.env.VITE_APP_IPDATA_URL;

    fetch(url)
      .then(data => data.json())
      .then(data => {
        const { latitude, longitude } = data;

        successCallback({ coords: { latitude, longitude } });
      })
      .catch(error => {
        console.error(error);
        store.commit('rocket/setUserLocationError', true);
      });
  }

  function fetchDoubleLocation() {
    navigator.geolocation.getCurrentPosition(successCallback, fetchIpData, { ...options, enableHighAccuracy: false });
  }

  function getUserLocation() {
    navigator.geolocation.getCurrentPosition(successCallback, fetchDoubleLocation, {
      ...options,
      enableHighAccuracy: true,
    });
  }

  return { getUserLocation };
};
