import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from '@/entities/locales/ru.json';
import kk from '@/entities/locales/kk.json';
// import { client } from '@/api';

Vue.use(VueI18n);

const currentLocale = localStorage.getItem('lang');

export const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: 'ru',
  legacy: true,
  messages: {
    ru,
    kk,
  },
});

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  // client.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  localStorage.setItem('lang', lang);

  return lang;
}
