import { orderPaymentTypes } from '@/entities/enums/OrderPaymentTypes';

const MobileDetailsModule = {
  namespaced: true,
  state: {
    deliveryTimeType: 1,
    datetime: '',
    paymentType: 2,
    selectedCardToken: null,
    datetimeToDisplay: '',
  },
  getters: {
    getDeliveryTimeType: state => state.deliveryTimeType,
    getPlannedDatetime: state => state.datetime,
    getPaymentType: state => state.paymentType,
    getSelectedCardToken: state => state.selectedCardToken,
    getDatetimeToShow: state => state.datetimeToDisplay,
  },
  mutations: {
    SET_DELIVERY_TIME_TYPE(state, type) {
      state.deliveryTimeType = type;
    },
    SET_DELIVERY_DATE_TIME(state, datetime) {
      state.datetime = datetime;
    },
    SET_PAYMENT_TYPE(state, type) {
      state.selectedCardToken = null;
      state.paymentType = type;
    },
    SET_CARD_TOKEN(state, id) {
      state.selectedCardToken = id;
      state.paymentType = orderPaymentTypes.card;
    },
    SET_INITAL_DETAILS(state) {
      state.deliveryTimeType = 1;
      state.datetime = '';
      state.paymentType = 2;
      state.selectedCardToken = null;
      state.datetimeToDisplay = '';
    },
    SET_DATETIME_TO_SHOW(state, string) {
      state.datetimeToDisplay = string;
    },
  },
};

export default MobileDetailsModule;
