
<UiModal name="error-handler">
  <div class="error-handler">
    <div class="relative">
      <h3 class="error-handler__title">{{ modalTitle }}</h3>
      <div class="error-handler__divider"></div>
    </div>

    <div class="error-handler__content">
      <div v-if="modalText">
        <p>{{ modalText }}</p>
        <p v-if="counterError > 1">Пожалуйста, обратитесь в поддержку чтобы решить вашу проблему.</p>
      </div>
      <p v-else>
        Произошла непредвиденная серверная ошибка. Вы можете перезагрузить страницу нажав на соответствующую кнопку
        ниже. Если это не помогает, просим обратиться в службу поддержки.
      </p>
    </div>

    <div class="error-handler__controllers">
      <UiButton
        v-if="counterError > 1"
        @click="goSupport"
      >
        <span class="text-base font-bold"> Написать в поддержку </span>
      </UiButton>
      <UiButton
        v-if="refreshPageBtn"
        @click="refreshPage"
      >
        <span class="text-base font-bold"> Перезагрузить </span>
      </UiButton>
      <UiButton
        v-else
        @click="hideErrorModal"
      >
        <span class="text-base font-bold"> Понятно </span>
      </UiButton>
    </div>
  </div>
</UiModal>
