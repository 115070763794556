import Vue from 'vue';

/**
 * Вычисляет общую стоимость задач в заказе.
 *
 * @param {Object} tasksPoints - объект с данными задач.
 * @returns {number} - общая стоимость задач.
 */
function countingTotalPrice(tasksPoints) {
  let total = 0;

  Object.keys(tasksPoints).forEach(pointKey => {
    let sum = tasksPoints[pointKey].reduce((total, current) => {
      return total + +current.extra_fields.price;
    }, 0);

    total += sum;
  });

  return total;
}

/**
 * Вычисляет общую сумму платежей для принятия товаров.
 *
 * @param {Object} itemsPoint - массив с данными товаров к конкретной точке.
 * @returns {number} - общая сумма наложенного платежа для принятия товаров.
 */
function countingAmountToAcceptPayment(itemsPoint) {
  let total = 0;

  total += itemsPoint.reduce((total, current) => +total + +current.price, 0);

  return total;
}

const MobileTasksModule = {
  namespaced: true,
  state: {
    tasks: [],
    selectedTasks: {}, // список всех выбранных тасков по айдишкам точек { [pointId]: [tasks array] }
    selectedTasksTotalPrice: null,
    amountToAcceptPayment: {}, // наложенка по айдишкам точек { [pointId]: [sum] }

    items: {},
    currentPoint: null,

    // то, что только в мобилке:
    currentTaskData: null,
    currentTaskEditing: null,

    // то, что только в десктопе:
    visibleTasksList: false,

    // то, что пока только в десктопе, но надо раскатить на мобилку:
    deliveryItemsErrors: {}, // ошибки, в структуре { [pointId]: { invalidTitle, invalidPrice } }

    // костыль временный до рефактора десктопа
    cashOnDeliveryErrorMinimal: false,
  },
  getters: {
    getTasks: state => state.tasks,
    getCurrentTaskData: state => state.currentTaskData,
    getCurrentTaskEditing: state => state.currentTaskEditing,
    getSelectedTasks: state => state.selectedTasks,
    getAmountToAcceptPaymentByPointId: state => id => state.amountToAcceptPayment[id],
    getFullAmountToAcceptPayment: state => state.amountToAcceptPayment,
    getPointItemsByPointId: state => id => state.items[id],
    getAllItems: state => state.items, // @todo: после миграции удалить нахер
    getSelectedTasksTotalPrice: state => state.selectedTasksTotalPrice,
    getHasReturnTask: state => {
      return Object.values(state.selectedTasks).some(tasks =>
        tasks.some(task => task.extra_fields.service_type === 'destination_change'),
      );
    },
    getTasksByPointId: state => id => state.selectedTasks[id],
    getTaskByPointAndTaskId:
      state =>
      ({ taskId, pointId }) =>
        state.selectedTasks[pointId].find(task => task.id === taskId),
    getCurrentPointTasks: state => state.currentPoint,
    getIsEditDeliveryPayment: state =>
      state.currentTaskEditing?.task?.extra_fields?.service_type === 'on_delivery_payment',
    getTasksVisible: state => state.visibleTasksList,
    getItemErrorByPointIdAndIndex: state => (pointId, index) => {
      if (state.deliveryItemsErrors[pointId] && state.deliveryItemsErrors[pointId][index]) {
        return state.deliveryItemsErrors[pointId][index];
      }

      return {
        invalidTitle: false,
        invalidPrice: false,
      };
    },
    getCashOnDeliveryError: state => state.cashOnDeliveryErrorMinimal,
    getPointDeliveryItemsErrors: state => id => state.deliveryItemsErrors[id],
  },
  mutations: {
    setTasks(state, res) {
      state.tasks = res;
    },
    setCurrentTaskData(state, data) {
      state.currentTaskData = data;
    },
    addTask(state, task) {
      const point = state.selectedTasks[state.currentPoint] || [];

      Vue.set(state.selectedTasks, state.currentPoint, point);
      state.selectedTasks[state.currentPoint].push(task);

      if (task.extra_fields.price_type === 'ON_DELIVERY_PAYMENT') {
        state.amountToAcceptPayment[state.currentPoint] = state.items[state.currentPoint].reduce((a, b) => ({
          price: +a.price + +b.price,
        })).price;
      }

      state.selectedTasksTotalPrice = countingTotalPrice(state.selectedTasks);
    },
    deleteTask(state, { pointId, taskId }) {
      const newTasksListInPoint = state.selectedTasks[pointId].filter(item => item.id !== taskId);

      Vue.set(state.selectedTasks, pointId, newTasksListInPoint);

      state.selectedTasksTotalPrice = countingTotalPrice(state.selectedTasks);
    },
    deleteTasksByPointId(state, id) {
      let newSelectedTasks = JSON.parse(JSON.stringify(state.selectedTasks));
      delete newSelectedTasks[id];

      Vue.set(state, 'selectedTasks', newSelectedTasks);
      state.selectedTasksTotalPrice = countingTotalPrice(state.selectedTasks);
    },
    setTaskComment(state, data) {
      state.selectedTasks[data.pointId].find(selected => selected.id === data.taskId).comment = data.comment;
    },
    updateTaskComment(state, data) {
      const tasksInPoint = state.selectedTasks[data.pointId];

      tasksInPoint.find(task => task.id === data.id).comment = data.comment;

      Vue.set(state.selectedTasks, data.pointId, [...tasksInPoint]);
    },
    setTaskEditing(state, data) {
      Vue.set(state, 'currentTaskEditing', data);
      state.currentPoint = data?.pointId || null;
    },
    setInitialTasksData(state) {
      state.selectedTasks = {};
      state.currentTaskData = null;
      state.currentTaskEditing = null;
      state.items = {};
      state.amountToAcceptPayment = {};
    },
    setCurrentPoint(state, id) {
      state.currentPoint = id;
    },

    // товары наложенки
    deleteItemsByPointId(state, id) {
      let newAmountToAcceptPayment = JSON.parse(JSON.stringify(state.amountToAcceptPayment));
      delete newAmountToAcceptPayment[id];

      Vue.set(state, 'amountToAcceptPayment', newAmountToAcceptPayment);
    },
    setDeliveryPaymentItems(state, items) {
      const newItemValue = state.items[state.currentPoint] || [];

      newItemValue.push(items);

      Vue.set(state.items, state.currentPoint, newItemValue);
    },
    deleteDeliveryPaymentItem(state, id) {
      const newSelectedItems = state.items[state.currentPoint].filter(item => item.id !== id);

      Vue.set(state.items, state.currentPoint, newSelectedItems);

      state.amountToAcceptPayment[state.currentPoint] = countingAmountToAcceptPayment(state.items[state.currentPoint]);
    },
    deleteAllDeliveryPaymentItems(state, pointId) {
      state.items[pointId] = [];
      Vue.set(state.amountToAcceptPayment, pointId, 0);
    },
    setItemTitle(state, data) {
      const editedItem = state.items[state.currentPoint];
      editedItem.find(item => item.id === data.id).title = data.title;

      Vue.set(state.items, state.currentPoint, editedItem);
    },
    setItemPrice(state, data) {
      let editedItem = state.items[state.currentPoint];
      let editedAmount = state.amountToAcceptPayment[state.currentPoint];

      editedItem.find(item => item.id === data.id).price = data.price;
      editedAmount = countingAmountToAcceptPayment(editedItem);

      Vue.set(state.items, state.currentPoint, editedItem);
      Vue.set(state.amountToAcceptPayment, state.currentPoint, editedAmount);
    },
    setDeliveryItemsErrors(state, errors) {
      Vue.set(state, 'deliveryItemsErrors', errors);
    },
    setDeliveryMinimalError(state, bool) {
      Vue.set(state, 'cashOnDeliveryErrorMinimal', bool);
    },

    // десктоп ui
    setTasksVisible(state, bool) {
      state.visibleTasksList = bool;
    },

    // для мультиточек в десктопе
    addEmptyTask(state, id) {
      Vue.set(state.selectedTasks, id, []);
    },
    addEmptyItemsTask(state, id) {
      Vue.set(state.items, id, []);
      Vue.set(state.amountToAcceptPayment, id, 0);
    },
  },
};

export default MobileTasksModule;
