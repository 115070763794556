import { pushCustomErrorInErrorHandler } from '@/utils-v2/plugins/errorHandler/helpers.js';

const errorHandlerPlugin = {
  install(Vue) {
    const errorHandler = new Vue({
      methods: {
        add(error) {
          pushCustomErrorInErrorHandler(error);
        },
        clear() {},
      },
    });

    Vue.prototype.$errorHandler = errorHandler;
  },
};

export default errorHandlerPlugin;
