// backend
export const errorCodesList = {
  ALL_POINTS_HAVE_BEEN_CANCELED: {
    title: 'Ошибка отмены точки',
    text: 'Все точки в заказе уже отменены.',
  },
  ORDER_HAS_BEEN_CANCELED: {
    title: 'Ошибка отмены заказа',
    text: 'Заказ уже отменен.',
  },
  GROUP_DOES_NOT_EXIST: {
    title: 'Ошибка получения заказа',
    text: 'Такой заказ отсутствует в базе данных.',
  },
  EXPECTED_TWO_POINTS_FOR_AN_URGENT: {
    title: 'Ошибка создания заказа',
    text: 'Срочный заказ можно создать только с двумя точками.',
  },
  BALANCE_IS_NOT_ENOUGH_TO_PAY: {
    title: 'Ошибка оплаты',
    text: 'Баланса компании не достаточно для оплаты. Обратитесь к менеджеру для того, чтобы вам пополнили баланс или оплатите заказ картой.',
  },
  WRONG_ADDRESS_COORDINATE: {
    title: 'Ошибка создания заказа',
    text: 'Адреса должны находится в одном городе.',
  },
  WRONG_ADDRESS_ZONE: {
    title: 'Ошибка создания заказа',
    text: 'Адрес не входит в зону доставки.',
  },
  TARIFF_NOT_FOUND: {
    title: 'Ошибка создания заказа',
    text: 'Тариф не найден. Попробуйте перезагрузить страницу и создать заказ ещё раз.',
  },
  TARIFF_DOES_NOT_EXIST: {
    title: 'Ошибка создания заказа',
    text: 'Тариф не существует. Попробуйте перезагрузить страницу и создать заказ ещё раз.',
  },
  NOT_ENOUGH_FUNDS_ON_THE_BALANCE: {
    title: 'Ошибка оплаты',
    text: 'Недостаточно средств на балансе. Обратитесь к менеджеру для того, чтобы вам пополнили баланс или оплатите заказ картой',
  },
  WRONG_INPUT_ADDRESS: {
    title: 'Ошибка создания заказа',
    text: '',
  },
  BEAUTIFUL_PARSING: {
    title: 'Beautiful Parsing',
    text: '',
  },
  CITY_IS_CLOSED: {
    title: 'Доставка сейчас недоступна',
    text: 'Уважаемые партнёры, в данный момент наблюдается повышенная загруженность, в связи с этим, создание новых доставок сейчас недоступно. Заявки, созданные ранее, будут доставлены. Делаем всё, чтобы как можно скорее открыть возможность создания новых.',
  },
};

// custom
export const errorCustomCodesList = {
  'no-tariffs': {
    title: 'Ошибка загрузки тарифов',
    text: '',
    request: '/deliveries/orders/tariffs',
  },
  'max-distance-order': {
    title: 'Ошибка создания заказа',
    text: 'В вашем сохранённом адресе указана максимальная дистанция заказа. Дистанция заказа, который вы пытаетесь создать превышает указанную в сохранённом адресе.',
    request: '/deliveries/groups',
  },
};
