/* eslint-disable */

const app_id = import.meta.env.VITE_APP_INTERCOM_APP_ID;
const api_base = 'https://api-iam.intercom.io';

export function initIntercom() {
  const intercom = window.Intercom;

  if (typeof intercom === 'function') {
    intercom('reattach_activator');
    intercom('update', window.intercomSettings);
  } else {
    const intercomTemplate = function () {
      intercomTemplate.c(arguments);
    };

    intercomTemplate.q = [] as any;
    intercomTemplate.c = function (args: any) {
      intercomTemplate.q.push(args);
    };

    window.Intercom = intercomTemplate;

    const addIntercomToDOM = function () {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}/?version=${Date.now()}`;
      const firstScriptOnPage = document.getElementsByTagName('script')[0];
      // @ts-expect-error
      firstScriptOnPage.parentNode.insertBefore(script, firstScriptOnPage);
    };

    if (document.readyState === 'complete') {
      addIntercomToDOM();
      // @ts-expect-error
    } else if (window.attachEvent) {
      // @ts-expect-error
      window.attachEvent('onload', addIntercomToDOM);
    } else {
      window.addEventListener('load', addIntercomToDOM, false);
    }
  }
}

export function showIntercom() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('show');
  }
}

export function showIntercomWithCustomMessage(message: string) {
  if (typeof window.Intercom === 'function') {
    window.Intercom('showNewMessage', message);
  }
}

export function hideDialogIntercom() {
  window.Intercom('hide');
}

export function showIntercomEvent(callback: Function) {
  if (typeof window.Intercom === 'function') {
    window.Intercom('onShow', callback);
  }
}

export function updateVisibleIntercomButton(visible: boolean) {
  window.intercomSettings = {
    ...window.intercomSettings,
    hide_default_launcher: visible,
  };

  if (typeof window.Intercom === 'function') {
    window.Intercom('update', window.intercomSettings);
  }
}

export function updateCompanyName(name: string) {
  window.intercomSettings = {
    ...window.intercomSettings,
    name,
  };
}

export function setIntercomSettings(user_id: number, first_name: string, phone: string, isMobile: boolean) {
  window.intercomSettings = {
    api_base,
    app_id,
    hide_default_launcher: isMobile,
    name: first_name,
    phone,
    email: phone,
    user_id: `rocket_${user_id}`,
  };
}
