
<transition name="fade">
  <div
    v-if="modal.state.activeName === name"
    class="fixed left-0 top-0 z-backdrop flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute z-backdrop h-full w-full bg-overlay"
      @click="onBackdropTap"
    ></div>
    <div
      class="modal modal-shadow relative z-modal overflow-y-auto overflow-x-hidden rounded-xl bg-white p-5"
      :class="[{ 'lg:w-450': modal.state.activeName == 'cancel-confirm-modal' }]"
    >
      <button
        class="absolute z-10 h-4 w-4 focus:outline-none"
        style="right: 20px; top: 22px"
        @click="onBackdropTap"
      >
        <img
          class="h-5 w-5"
          src="@/assets/images/icons/close-modal.svg"
          alt=""
        />
      </button>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</transition>
