import googleAnalytics from '@/utils-v2/di/providers/analytics/google-analytics.providers';
import amplitudeAnalytics from '@/utils-v2/di/providers/analytics/amplitude.providers';

const providerKey = 'analytics';

export default {
  key: providerKey,
  value() {
    return {
      event(name, payload) {
        googleAnalytics.event(name, payload);
        amplitudeAnalytics.event(name, payload);
      },
    };
  },
};
